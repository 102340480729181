var pomlo_app = {};

// Define constants
pomlo_app.BREAKPOINTS = {
    SM: 576,
    MD: 768,
    LG: 992,
    XL: 1140
};
pomlo_app.DEFAULT_DURATION = 300;
pomlo_app.HEADER_HEIGHT = 140;

// Observer and animation functions
pomlo_app.observeSection = function(selector, func, options) {
    var section = document.querySelector(selector);

    var sectionObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
            if (entry.intersectionRatio > 0) {
                func(options);
                observer.unobserve(entry.target);
            }
        });
    });

    if (section) {
        sectionObserver.observe(section);
    }
};


pomlo_app.animateHomeHeroItem = function() {
    jQuery('.home-hero__caption').each(function() {
        jQuery(this).addClass('animate__animated animate__fadeInRight animate__slow');
    });
};

pomlo_app.animateHomeNews = function() {
    jQuery('.article-hero-item').each(function() {
        jQuery(this).addClass('animate__animated animate__fadeIn animate__slow');
    });
};

pomlo_app.animateShopBottles = function(selector) {
    jQuery(selector).addClass('animate__animated animate__fadeIn animate__slow');
};

pomlo_app.counter = function() {
  jQuery('.counter').each(function () {
    jQuery(this).prop('Counter',0).animate({
        Counter: jQuery(this).text()
    }, {
        duration: 4000,
        easing: 'swing',
        step: function (now) {
            jQuery(this).text(Math.ceil(now));
        }
    });
  });
};

pomlo_app.togglerHeaderStyle = function() {
  if (jQuery(window).scrollTop() > pomlo_app.HEADER_HEIGHT ) {
    if(jQuery(window).width() >= pomlo_app.BREAKPOINTS.MD) {
      jQuery('#navigation').addClass('navbar-fixed');
      jQuery('#navigation__fixed').addClass('fixed-top');
    }
    jQuery('#scroll-top').addClass('show');
  } else {
    jQuery('#navigation').removeClass('navbar-fixed');
    jQuery('#navigation__fixed').removeClass('fixed-top');
    jQuery('#scroll-top').removeClass('show');
  }
};


pomlo_app.showOtherAddressForm = function () {
  if (jQuery('#other-address').is(':checked')) {
    jQuery("#other-address__form").slideDown("slow");
  } else {
    jQuery("#other-address__form").slideUp("slow");
  }
};


pomlo_app.numberCartEltsVerif = function(context) {
   var countElts = 0;
   var countEltsBottles = 0;
   var variationId;
   var button;
   var cartItem;
   var variationContainer;
   var alertContainer;
   var alertWrapper;
   var alertStartTag;
   var alertEndTag;

   if(context == 'form') {
       button = '.checkout-link';
       cartItem = '.woocommerce-cart-form__cart-item';
       variationContainer = '.product-remove a';
       alertContainer = '.woocommerce-message';
       alertWrapper = '.message-wrapper';
       alertStartTag = '<div class="message custom-message col-12" role="alert"><i class="fas fa-exclamation-circle"></i>';
       alertStartTagClass = '.custom-message';
       alertEndTag = '</div>';
   } else if(context == 'widget') {
       button = '.checkout-link';
       cartItem = '.woocommerce-mini-cart-item';
       variationContainer = '.woocommerce-mini-cart-item__link';
       alertContainer = '.widgetAlertQuantity';
       alertWrapper = '.home-cart__summary';
       alertStartTag ='<div class="widgetAlertQuantity color--3"><strong>';
       alertStartTagClass = '.widgetAlertQuantity';
       alertEndTag = '</div><strong>';
   } else {
       return;
   }

   var messageCreation = function(message) {
       if(jQuery(alertContainer).length) {
           jQuery(alertContainer).empty();
           jQuery(alertContainer).append(message);
       } else {
           jQuery(alertWrapper).append(alertStartTag + message + alertEndTag);
       }
       jQuery(button).addClass('disabled');
       jQuery(button).attr("data-link", jQuery(button).attr("href"));
       jQuery(button).attr("href", "#!");
   };

   var messageDelete = function() {
       jQuery(alertStartTagClass).remove();
       jQuery(button).removeClass('disabled');
       jQuery(button).attr("href", jQuery(button).attr("data-link"));
   };


   jQuery(cartItem).each(function() {
       if(context == 'form') {
           selectElt = jQuery(this).find('.input-text');
           selectedQuantity = parseInt(selectElt.val());
       } else {
           selectElt = '';
           selectedQuantity =  parseInt(jQuery(this).find('.quantity').text().slice(0,-1));
       }

       variationId = jQuery(this).find(variationContainer).data('product_sku');
       productId = jQuery(this).find(variationContainer).data('product_id');

       if(variationId == 121){
           countEltsBottles = countEltsBottles + (selectedQuantity * 2);
           return;
       } else if ((variationId == 119 || variationId == "") && !productsExcluded.includes(productId)) {
           countEltsBottles = countEltsBottles + selectedQuantity;
           return;
       } else if (variationId == 120 && (selectedQuantity % 12) != 0) {
           messageCreation('Les demi-bouteilles ne sont vendues que par carton de 12, veuillez modifier votre commande');
           return;
       }
   });

   if((countEltsBottles != 1 && countEltsBottles != 2 && countEltsBottles != 3 && countEltsBottles % 6 != 0)){
       messageCreation('Les bouteilles ne sont vendues que par 1, 2, 3 ou par carton de 6, veuillez modifier votre commande');
       return;
   } else {
       messageDelete();
       return;
   }

};

pomlo_app.initContactMap = function() {
    mapboxgl.accessToken = mapBoxToken;
    pomlo_app.map = new mapboxgl.Map({
        container: 'contact-map',
        style: 'mapbox://styles/pomlo/ckqs3882p3l6k18mk36qoxu9o',
        center: [3.302135984807168, 48.975723912109764],
        zoom: 14
    });

    var popup = new mapboxgl.Popup({className: 'popup'})
      .setLngLat([3.302135984807168, 48.975723912109764])
      .setHTML(mapBoxAddress)
      .setMaxWidth("320px")
      .addTo(pomlo_app.map);

    var marker = new mapboxgl.Marker({color: '#BE9A45'})
        .setLngLat([3.302135984807168, 48.975723912109764])
        .setPopup(popup)
        .addTo(pomlo_app.map);

     var nav = new mapboxgl.NavigationControl();
     pomlo_app.map.addControl(nav, 'bottom-left');
     pomlo_app.map.scrollZoom.disable();
};

pomlo_app.cookieNotFound = function() {
  var cookies = document.cookie.split(";");

  for(var i = 0; i < cookies.length; i++) {
    var cookieVar = cookies[i].split("=");

    if("modalShowed" == cookieVar[0].trim()) {
      return false;
    }
  }

  return true;
};


jQuery(document).ready(function($) {
    // Init interface

    /* Global functions */
    // Taking care of modal
    if(jQuery("#home-hero__slider").length) {
      if (pomlo_app.cookieNotFound()) {
        var cookieString = "modalShowed=true;";
        if (location.protocol !== 'http:') {
          cookieString += "Secure";
        }
        jQuery('#notice-modal').modal('show');
        console.log(cookieString);
        document.cookie = cookieString;
      }
    }

    // Scroll top function
    jQuery("#scroll-top__toggler").click(function() {
        jQuery("#navigation").animatescroll( {
            padding: pomlo_app.HEADER_HEIGHT,
            scrollSpeed: 600,
            easing: 'easeInOutQuad'
        });
    });

    // Opening side navigation
    jQuery("#side-nav__open, #footer-side-nav__open").click(function() {
        jQuery("#side-navigation").addClass("open");
    });
    jQuery("#side-nav__close").click(function() {
        jQuery("#side-navigation").removeClass("open");
    });

    // Show / hide mini cart

    if(jQuery(".woocommerce-cart-form").length == 0){
      jQuery("#home-cart__toggler").click(function() {
        if(jQuery("#home-cart").css("display") === "none") {
          jQuery("#home-cart").slideDown("slow");
        } else {
          jQuery("#home-cart").slideUp("slow");
        }
      });
    }

    jQuery("#home-cart__close").click(function() {
      jQuery("#home-cart").slideUp("slow");
    });

    // Show / hide header research
    jQuery("#nav__research__toggler").click(function() {
      if(jQuery("#navigation__research__input").hasClass("open")) {
        jQuery("#navigation__research__input").removeClass("open");
      } else {
        jQuery("#navigation__research__input").find("input").focus();
        jQuery("#navigation__research__input").addClass("open");
      }
    });

    /* Home page functions */
    // Hiding loader
    if(jQuery("#loader").length) {
      jQuery('.img-loader-wait').imagesLoaded(function() {
          jQuery('.img-bg-loader-wait').imagesLoaded( { background: true }, function() {
              var delay = document.location.host ? 0 : 500;
              setTimeout(function(){
                  jQuery("#loader").fadeOut();
              }, delay);
          });
      });
    }

    // Setting up carrousels
    jQuery("#home-hero__slider").owlCarousel({
        items: 1,
        loop: true,
        autoplay: true,
        dots: true,
        animateOut: 'fadeOut'
    });

    jQuery("#testimony-hero__slider").owlCarousel({
        items: 1,
        loop: true,
        autoplay: true,
        dots: true,
        animateOut: 'fadeOut',
        autoHeight: true,
        nav : true
    });

    jQuery("#article-hero__slider").owlCarousel({
        items: 3,
        loop: true,
        autoplay: true,
        dots: true,
        autoHeight: false,
        responsive : {
          0 : {
              items : 1
          },
          480 : {
              items: 2
          },
          768 : {
              items: 3
          }
        }
    });

    // Setting height on carousel items for articles hero
    jQuery('.img-articles-wait').imagesLoaded(function() {
        pomloUIBlock.setBlockHeight("#articles-hero");
    });

    // Setup intersection observers to trigger actions
    pomlo_app.observeSection('#article-hero__slider', pomlo_app.animateHomeNews);
    pomlo_app.observeSection('#home-hero__slider', pomlo_app.animateHomeHeroItem);
    pomlo_app.observeSection('.counter', pomlo_app.counter);

    /* Product page functions */
    // Enabling zoom on product image
    if(jQuery("#product-zoom").length > 0) {
      jQuery('#product-zoom').magnify();
    }

    /* Shop page functions */
    // Showing products thanks to observe section
    jQuery('.products-item').each(function(index) {
        jQuery(this).attr('id', 'product-item' + index);
        var selector = '#' + jQuery(this).attr('id');
        jQuery('.img-products-wait').imagesLoaded(function() {
            jQuery("#products-loader").fadeOut();
            pomlo_app.observeSection(selector, pomlo_app.animateShopBottles, selector);
        });
    });

    /* Cart page functions */
    // Showing state input when country changes on cart total table
    $('#cart-total__country').change(function() {
      if($(this).val() === "default") {
        jQuery("#cart-total__state").slideDown("slow");
      } else {
        jQuery("#cart-total__state").slideUp("slow");
      }
    });

    // Showing address form when link cliked on cart total table
    jQuery("#cart-total__toggler").click(function() {
      if(jQuery("#cart-total__form").css("display") === "none") {
        jQuery("#cart-total__form").slideDown("slow");
      } else {
        jQuery("#cart-total__form").slideUp("slow");
      }
    });

    // Showing address form when title is checked
    pomlo_app.showOtherAddressForm();
    jQuery('#other-address').change(function() {
      pomlo_app.showOtherAddressForm();
    });

    // Verify the number of bottles
    if(jQuery(".woocommerce-cart-form").length){
        pomlo_app.numberCartEltsVerif('form');

        jQuery(document.body).on('updated_cart_totals', function() {
          pomlo_app.numberCartEltsVerif('form');
        });
    } else if (jQuery(".woocommerce-cart-form").length == 0) {
        jQuery("#home-cart__toggler").click(function() {
            pomlo_app.numberCartEltsVerif('widget');
        });
    }

    /* Contact page functions */
    // Contact map init
    if(jQuery("#contact-map").length) {
      pomlo_app.initContactMap();
    }

});

jQuery(window).scroll(function() {
  pomlo_app.togglerHeaderStyle();
});

jQuery(window).resize(function() {
  pomlo_app.togglerHeaderStyle();
});
